import { defineStore } from "pinia";

type BetaTesterStatusResponse = {
  is_beta_tester: boolean;
};

export const useBetaTesterStatusStore = defineStore(
  "beta-tester-status",
  () => {
    const betaTesterStatus = ref<BetaTesterStatusResponse | null>(null);
    const runtimeConfig = useRuntimeConfig();

    const fetchBetaTesterStatus = async () => {
      await useAutoRequest<BetaTesterStatusResponse>(
        `${runtimeConfig.public.backendApiUrl}/public/users/beta_tester_status`,
      ).then((res) => {
        if (res.data && res.data.value) {
          betaTesterStatus.value = res.data.value;
        }
      });
    };

    const isBetaTester = computed(() => {
      if (betaTesterStatus && betaTesterStatus.value) {
        return betaTesterStatus.value.is_beta_tester;
      }
      return false;
    });

    onMounted(() => {
      setTimeout(async () => {
        await fetchBetaTesterStatus();
      }, 0);
    });

    return {
      isBetaTester,
    };
  },
);
