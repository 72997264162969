<template>
  <div class="Step__container" :class="wrapperClass">
    <div class="Step__wrapper">
      <div class="Step__header" :class="{ 'flex-end': !$slots.step }">
        <span v-if="$slots.step" class="Step__current-step">
          Step
          <slot name="step" />
        </span>
        <button class="Step__button-close" @click="handleClickSkip">
          <o-icon custom-size="mdi-16px" icon="close" />
        </button>
      </div>
      <div class="Step__body">
        <h3 class="Step__title">{{ title }}</h3>
        <p class="Step__text">
          <slot name="text" />
        </p>
      </div>
      <button class="Step__button-next" @click="handleClickNext">
        <slot name="next-button-text"> Next </slot>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
type Props = {
  title: string;
  wrapperClass?: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "next"): void;
  (e: "skip"): void;
}>();

const handleClickNext = () => {
  emit("next");
};

const handleClickSkip = () => {
  emit("skip");
};
</script>
<style scoped lang="scss">
.Step {
  &__container {
    background: $brand-primary-600;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    &::after {
      content: "";
      position: absolute;
      border-width: 8px;
      border-style: solid;
      border-color: $brand-primary-600 transparent transparent transparent;
    }
    &.step-1 {
      width: calc(100% - 32px);
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        content: unset;
      }
      @include md {
        max-width: 406px;
        left: 20px;
        transform: unset;
        z-index: 2;
      }
    }
    &.step-2 {
      width: calc(100% - 32px);
      left: 16px;
      bottom: unset;
      top: 56px;
      right: unset;
      max-width: 249px;
      &::after {
        bottom: 100%;
        right: 10px;
        border-color: transparent transparent $brand-primary-600 transparent;
      }
      @include sm {
        max-width: 304px;
        left: unset;
        right: 36px;
      }
      @include md {
        bottom: 18px;
        left: 340px;
        top: unset;
        &::after {
          content: unset;
        }
      }
    }
    &.step-3 {
      top: 140px;
      left: 8px;
      bottom: unset;
      width: 100%;
      max-width: 318px;
      z-index: 1;
      &::after {
        bottom: 100%;
        left: 10px;
        border-color: transparent transparent $brand-primary-600 transparent;
      }
      @include md {
        top: 244px;
      }
    }
    &.step-4 {
      left: 0;
      bottom: 0;
      transform: translateY(calc(100% + 8px));
      width: 240px;
      max-width: 240px;
      z-index: 1;
      &::after {
        bottom: 100%;
        left: 10px;
        border-color: transparent transparent $brand-primary-600 transparent;
      }
      &.odd {
        left: unset;
        right: 0;
        &::after {
          left: unset;
          top: unset;
          right: 10px;
        }
      }
      @include sm {
        left: 20px;
        right: unset;
        &.odd {
          left: unset;
          right: 20px;
        }
        &::after {
          bottom: 100%;
          top: unset;
          border-color: transparent transparent $brand-primary-600 transparent;
        }
      }
      @include custom-min-width(992px) {
        left: 20px;
        right: unset;
        &::after {
          right: unset;
          left: 10px;
        }
      }
    }
    &.step-5 {
      top: 88px;
      right: 40px;
      bottom: unset;
      width: calc(100% - 48px);
      z-index: 1;
      max-width: 318px;
      &::after {
        bottom: 100%;
        right: 10px;
        border-color: transparent transparent $brand-primary-600 transparent;
      }
      @include md {
        right: calc(50% - 340px);
      }
      @include custom-min-width(1163px) {
        left: max(335px, 50% - 257px);
        top: 294px;
        z-index: 2;
        &::after {
          top: 12px;
          left: -16px;
          right: unset;
          border-color: transparent $brand-primary-600 transparent transparent;
        }
      }
    }
    &.permissions {
      bottom: unset;
      top: 16px;
      left: 16px;
      z-index: 3;
      max-width: 322px;
      &::after {
        content: unset;
      }
    }
    &.invite {
      z-index: 3;
      bottom: calc(100% - 12px);
      &::after {
        right: 10px;
      }
    }
    &.manage-participants,
    &.manage-waiting-room {
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 40px);
      z-index: 2;
      &::after {
        content: unset;
      }
    }
    &.waiting-room {
      bottom: unset;
      top: 138px;
      width: 247px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      &::after {
        right: 10px;
        bottom: 100%;
        border-color: transparent transparent $brand-primary-600 transparent;
      }
    }
  }
  &__wrapper {
    padding: 12px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.flex-end {
      justify-content: flex-end;
    }
  }
  &__current-step {
    @include LabelSmall;
    color: $color-element-light-basic;
  }
  &__button-close {
    color: $color-element-light-basic;
    border: none;
    background: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 0;
  }
  &__body {
  }
  &__title {
    @include TitleMedium;
    color: $color-element-light-strong;
  }
  &__text {
    @include BodyLarge;
    color: $color-element-light-strong;
  }
  &__button-next {
    @include button-primary;
    color: $brand-primary-600;
    background: $color-element-light-strong;
    width: fit-content;
    display: flex;
    margin-top: 12px;
    margin-left: auto;
  }
}
</style>
