<template>
  <div class="link-share__wrapper">
    <o-tooltip
      class="link-share"
      :triggers="['click']"
      :auto-close="['outside', 'escape']"
    >
      <template #content>
        <o-icon icon="check-circle-outline"></o-icon>
        Link copied!
      </template>
      <o-field>
        <template #label
          >{{ label }}
          <span class="o-field__label--description">{{
            description
          }}</span></template
        >
        <o-button class="link-share" icon-right="link" @click="handleClick">{{
          url
        }}</o-button>
      </o-field>
    </o-tooltip>
  </div>
</template>
<script setup lang="ts">
type Props = {
  label: string;
  description?: string;
  url: string;
};

const props = defineProps<Props>();

const handleClick = async () => {
  await navigator.clipboard.writeText(props.url);
};
</script>
