<template>
  <div v-if="!skipped && betaEndedOnboardingStore.shouldShowBetaEndedStep">
    <div class="BetaEnded__overlay"></div>
    <div class="BetaEnded__wrapper">
      <div class="BetaEnded__content">
        <div class="BetaEnded__illustration-wrapper">
          <IllustrationOnboardingStart />
        </div>
        <button class="BetaEnded__button-skip" @click="handleSkip">Skip</button>
        <h3 class="BetaEnded__title">KingsConference plans Update!&nbsp;🎉</h3>
        <p class="BetaEnded__description">
          We've got some fantastic news to share! <br />✨ You can now pay with
          Espees!<br />
          ✨ We've added medium-sized rooms for more flexible meetings. <br />✨
          With the beta phase officially wrapped up, we've revamped our pricing
          plans! Head over to our pricing page for all the details! 🚀
        </p>
        <NuxtLink
          v-if="isDashboard"
          class="BetaEnded__button"
          to="/#plans"
          @click="handleCheckPricing"
        >
          Check new pricing
        </NuxtLink>
        <button v-else class="BetaEnded__button" @click="handleSkip">
          Got it
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useBetaEndedOnboardingStore } from "~/store/betaEndedOnboarding";
const skipped = ref(false);

const route = useRoute();

const analytics = useAnalytics();

const betaEndedOnboardingStore = useBetaEndedOnboardingStore();

const isDashboard = computed(() => {
  return route.name === "dashboard";
});

const handleSkip = () => {
  skipped.value = true;
  analytics.trackOtherEvent("beta_ended_skip");
  betaEndedOnboardingStore.setBetaEndedStep("SKIPPED");
};

const handleCheckPricing = () => {
  analytics.trackOtherEvent("beta_ended_check_pricing");
  betaEndedOnboardingStore.setBetaEndedStep("FINISHED");
};
</script>
<style scoped lang="scss">
.BetaEnded {
  &__wrapper {
    width: 100%;
    background: $brand-primary-600;
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    @include md {
      max-width: 414px;
      border-radius: 20px;
      bottom: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @include custom-min-width(1163px) {
      left: calc(50% + 170px);
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-basic-overlay;
    z-index: 4;
  }
  &__illustration-wrapper {
    width: 162px;
    display: none;
    @media (min-height: 520px) {
      display: flex;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 24px 20px 20px 20px;
  }
  &__button-skip {
    @include LabelLarge;
    position: absolute;
    right: 2px;
    top: 14px;
    padding: 10px 18px;
    border: none;
    background: none;
    color: $color-element-light-strong;
    cursor: pointer;
  }
  &__title {
    @include TitleLarge;
    color: $color-element-light-strong;
    padding-top: 20px;
    padding-bottom: 12px;
    @include md {
      @include TitleXLarge;
    }
  }
  &__description {
    @include BodyLarge;
    color: $color-element-light-strong;
    padding-bottom: 20px;
  }
  &__button {
    @include button-primary;
    background: $color-element-light-strong;
    color: $brand-primary-500;
    border: none;
    text-decoration: none;
    text-align: center;
  }
}
</style>
