<template>
  <svg
    width="203"
    height="166"
    viewBox="0 0 203 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M110.174 1.07935H12.8587C8.48745 1.07935 4.94385 4.62295 4.94385 8.99419V106.309C4.94385 110.681 8.48745 114.224 12.8587 114.224H110.174C114.545 114.224 118.089 110.681 118.089 106.309V8.99419C118.089 4.62295 114.545 1.07935 110.174 1.07935Z"
      fill="#3183FF"
    />
    <path
      d="M61.1248 55.1691C67.3666 55.949 69.3122 53.299 70.227 51.0079C71.266 48.4092 70.5967 46.1748 69.7872 43.2712C69.7872 43.2712 71.8489 42.1486 71.5277 40.8614C71.2579 39.8224 68.9128 39.7064 68.9128 39.7064C68.8211 39.2423 68.7392 38.761 68.6673 38.2627C68.1465 34.6196 67.3666 32.5391 67.3666 32.5391C65.5451 33.0599 63.2054 32.8089 62.4255 32.5391C61.3721 35.458 59.47 37.9953 56.9637 39.8251C56.9637 39.8251 51.2401 33.5834 47.5997 38.2654C44.6313 42.0838 48.9004 46.8468 51.7609 47.3676C50.0608 50.954 45.4867 53.0345 43.1066 53.9035C43.2524 55.0315 43.39 56.0785 43.5114 56.9906H61.3218L61.1248 55.1691Z"
      fill="#EAA58B"
    />
    <path
      d="M123.07 77.8153C124.01 74.473 124.777 71.0845 125.369 67.6634L119.127 50.757C119.127 50.757 121.729 43.7407 119.397 42.6937C117.066 41.6467 111.844 48.4227 109.763 53.8792C108.865 56.2377 106.409 61.6591 103.913 67.0535L123.07 77.8153Z"
      fill="#EAA58B"
    />
    <path
      d="M97.2773 81.1886C97.2773 81.1886 82.1925 68.4433 75.662 63.7586C69.1315 59.074 61.6457 59.8619 61.6457 59.8619L61.3192 56.9988H43.5088C43.7786 59.0308 43.954 60.3801 43.954 60.3801L30.6934 107.721L70.2244 104.598L69.1855 90.0263C69.1855 90.0263 90.504 107.837 102.739 106.153C113.774 104.625 120.042 88.6932 123.067 77.8181L103.908 67.0563C100.634 74.1426 97.2773 81.1886 97.2773 81.1886Z"
      fill="#3EC1F3"
    />
    <path
      d="M51.761 47.3756C48.9005 46.8548 44.6287 42.0919 47.5998 38.2734C51.2402 33.5914 56.9638 39.8332 56.9638 39.8332C59.4701 38.0034 61.3722 35.466 62.4256 32.5471C63.2055 32.817 65.5452 33.0679 67.3667 32.5471C67.3667 32.5471 71.2688 31.2464 71.007 28.1269C70.6589 23.9387 64.2445 25.7845 64.2445 25.7845C64.2445 25.7845 57.2282 20.5817 49.6723 23.963C41.6198 27.5872 40.8372 34.8921 41.35 39.8332C41.6468 42.6694 42.4591 49.0083 43.0986 53.9115C45.4868 53.0399 50.0609 50.962 51.761 47.3756Z"
      fill="#30242A"
    />
    <path
      d="M124.104 70.9151C122.913 71.4062 121.869 71.5493 121.405 71.2821C120.239 70.6183 120.056 69.2285 121.44 67.2748C121.44 67.2748 118.151 68.5566 117.136 66.8889C116.121 65.2212 117.646 63.6857 117.646 63.6857C117.646 63.6857 115.317 64.328 114.748 62.2555C114.178 60.183 116.097 59.0982 116.097 59.0982C116.097 59.0982 114.785 58.6097 114.748 57.2416C114.696 55.2527 117.937 53.639 118.871 53.2153"
      stroke="#A15842"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M69.7604 89.9695L68.797 78.7192"
      stroke="#4756DF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M60.8794 55.6495C59.3702 55.3082 57.9167 54.7559 56.5617 54.0088"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M67.6661 45.9373C67.2371 46.2071 65.6233 46.4122 64.9676 45.1736"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M65.286 40.0922C65.7167 40.0922 66.0659 39.7431 66.0659 39.3124C66.0659 38.8816 65.7167 38.5325 65.286 38.5325C64.8553 38.5325 64.5061 38.8816 64.5061 39.3124C64.5061 39.7431 64.8553 40.0922 65.286 40.0922Z"
      fill="#A15842"
    />
    <path
      d="M50.9811 41.6548L53.8416 43.2146"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M123.952 154.352L143.876 148.455C142.357 143.204 139.45 133.249 137.58 127.45L112.718 137.828C115.972 143.655 119.731 149.184 123.952 154.352Z"
      fill="#EAA58B"
    />
    <path
      d="M124.33 154.797C123.747 157.011 123.386 159.277 123.251 161.562H158.497C157.821 159.935 156.62 158.581 155.086 157.714L144.877 151.934C144.877 151.934 144.488 150.584 143.876 148.453L123.953 154.352C124.195 154.643 124.33 154.797 124.33 154.797Z"
      fill="#FFBE21"
    />
    <path
      d="M123.289 164.941H159.179L159.069 163.829C158.991 163.049 158.797 162.285 158.494 161.562H123.248C123.171 162.687 123.184 163.817 123.289 164.941Z"
      fill="#FF7235"
    />
    <path
      d="M105.602 121.246C107.324 127.183 109.961 132.879 112.719 137.828L137.58 127.45C137.31 126.578 137.041 125.801 136.814 125.145C129.034 102.664 124.33 84.5834 112.886 82.751C94.8731 79.8852 64.231 110.908 59.63 115.673L66.2576 119.691L87.0958 129.479L94.6869 131.23L105.602 121.246Z"
      fill="#A15842"
    />
    <path
      d="M105.036 121.014C104.446 118.137 104.156 115.207 104.172 112.27"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M134.158 139.628L138.978 141.736"
      stroke="#EAECFC"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M131.419 142.972L136.239 145.082"
      stroke="#EAECFC"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M17.0361 17.4622C19.4057 17.4622 21.3267 15.5412 21.3267 13.1716C21.3267 10.8019 19.4057 8.88086 17.0361 8.88086C14.6664 8.88086 12.7454 10.8019 12.7454 13.1716C12.7454 15.5412 14.6664 17.4622 17.0361 17.4622Z"
      fill="white"
    />
    <path
      d="M30.0431 17.4622C32.4128 17.4622 34.3338 15.5412 34.3338 13.1716C34.3338 10.8019 32.4128 8.88086 30.0431 8.88086C27.6734 8.88086 25.7524 10.8019 25.7524 13.1716C25.7524 15.5412 27.6734 17.4622 30.0431 17.4622Z"
      fill="white"
    />
    <path
      d="M43.0472 17.4622C45.4169 17.4622 47.3379 15.5412 47.3379 13.1716C47.3379 10.8019 45.4169 8.88086 43.0472 8.88086C40.6775 8.88086 38.7565 10.8019 38.7565 13.1716C38.7565 15.5412 40.6775 17.4622 43.0472 17.4622Z"
      fill="white"
    />
    <path
      d="M7.20821 3.34383C8.70646 1.84559 10.7377 1.00269 12.8566 1H110.162C112.281 1.00269 114.312 1.84559 115.811 3.34383C117.309 4.84221 118.152 6.87373 118.154 8.99277V106.308C118.152 108.427 117.309 110.459 115.811 111.957C114.312 113.455 112.281 114.298 110.162 114.301H12.8571C10.7381 114.298 8.7066 113.455 7.20821 111.957C5.70996 110.459 4.86707 108.428 4.86438 106.309V8.99218C4.86707 6.87336 5.70996 4.84208 7.20821 3.34383Z"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M52.2979 139.982C41.6575 142.141 34.0746 144.915 34.0746 144.915L23.8363 140.379L23.8579 140.155L0 161.821L0.520818 162.445C1.17205 163.226 1.98699 163.854 2.90797 164.286C3.82895 164.717 4.83347 164.941 5.85045 164.941H53.7362L52.2979 139.982Z"
      fill="#EAA58B"
    />
    <path
      d="M34.0639 144.915C34.0639 144.915 41.6468 142.127 52.2872 139.982L53.7255 164.941H87.9026C97.1425 164.941 105.241 158.464 106.962 149.386C108.109 143.328 106.318 136.841 99.8788 132.429C89.5218 125.331 67.3667 116.043 61.6458 114.221L30.1726 121.508C24.1899 128.011 25.4906 138.673 25.4906 138.673L34.0639 144.915Z"
      fill="#A15842"
    />
    <path
      d="M52.3763 140.319C59.4519 138.951 67.356 137.766 78.42 138.282"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M62.1854 113.952C67.1912 115.547 84.7804 122.857 96.036 129.414"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M28.9367 140.344L2.6825 164.169C3.66428 164.677 4.75347 164.941 5.85868 164.941H24.9698L34.0639 144.915C32.6396 143.099 30.9034 141.551 28.9367 140.344Z"
      fill="#FFBE21"
    />
    <path
      d="M25.4905 138.67L0 161.821L0.520818 162.444C1.11702 163.159 1.85131 163.745 2.67966 164.169L28.9365 140.343C27.8532 139.66 26.6974 139.099 25.4905 138.67Z"
      fill="#FF7235"
    />
    <path
      d="M19.6375 160.391V165"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M15.4763 160.391V165"
      stroke="#30242A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M44.0619 59.9429C44.8715 59.8674 46.5608 59.7297 48.25 59.7297"
      stroke="#4756DF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M119.127 50.7569C119.127 50.7569 124.201 49.0757 125.604 51.1914C127.007 53.307 125.707 55.617 125.707 55.617C125.707 55.617 129.239 56.3159 127.952 59.7323C127.952 59.7323 130.216 61.8911 128.057 64.1579C128.057 64.1579 130.108 65.5423 128.016 68.0654C125.925 70.5885 122.377 71.8434 121.386 71.2821C120.22 70.6182 120.037 69.2285 121.421 67.2747C121.421 67.2747 118.132 68.5565 117.117 66.8888C116.102 65.2211 117.627 63.6857 117.627 63.6857C117.627 63.6857 115.298 64.3279 114.729 62.2554C114.159 60.1829 116.078 59.0981 116.078 59.0981C116.078 59.0981 114.767 58.6097 114.729 57.2415C114.678 55.2527 118.167 53.5202 119.1 53.0993L119.127 50.7569Z"
      fill="#EAA58B"
    />
    <path
      d="M115.29 59.4625C117.292 59.1117 122.379 58.0269 125.747 55.6846"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M116.1 64.3225C120.328 63.7928 124.385 62.325 127.973 60.0264"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M119.878 68.2812C120.598 68.0276 125.299 66.341 128.152 64.533"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M47.8722 101.584C43.1633 102.159 37.9092 103.009 31.994 104.067L40.3163 81.6984L43.9566 60.3799C43.9566 60.3799 31.994 61.6806 26.5321 67.9358C21.4939 73.6918 5.72359 100.448 11.9599 114.494C18.1963 128.54 36.935 123.585 41.3498 122.285C43.7596 121.575 47.4108 119.94 51.1591 118.723L47.8722 101.584Z"
      fill="#3EC1F3"
    />
    <path
      d="M60.0834 117.344C65.807 118.124 72.3078 120.984 73.3495 117.344C73.3495 117.344 76.8576 117.255 75.7512 113.919C75.7512 113.919 77.5106 113.701 77.7697 112.4C78.2905 109.801 72.0488 107.459 72.0488 107.459C72.0488 107.459 76.9898 108.239 78.0314 107.189C79.3564 105.861 78.0665 103.748 69.9952 101.792C64.1339 100.373 57.0421 100.459 47.8671 101.581L51.162 118.709C54.3031 117.711 57.482 116.988 60.0834 117.344Z"
      fill="#EAA58B"
    />
    <path
      d="M74.2722 107.791C71.5008 106.728 66.2386 104.96 62.4256 104.339"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M75.859 113.811C72.2429 112.192 65.2591 109.191 61.5135 108.63"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M73.0553 117.614C70.2677 116.2 64.6898 113.544 60.7337 112.791"
      stroke="#A15842"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M47.9183 101.973C45.3763 102.302 37.2455 103.424 25.0994 105.77"
      stroke="#4756DF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <rect
      x="168"
      y="59"
      width="34.6154"
      height="34.6154"
      rx="17.3077"
      fill="white"
    />
    <path
      d="M190.115 74.8655V71.5001C190.115 70.9713 189.683 70.5386 189.154 70.5386H177.615C177.087 70.5386 176.654 70.9713 176.654 71.5001V81.1155C176.654 81.6443 177.087 82.077 177.615 82.077H189.154C189.683 82.077 190.115 81.6443 190.115 81.1155V77.7501L192.317 79.952C192.923 80.5578 193.962 80.1251 193.962 79.2693V73.3367C193.962 72.4809 192.923 72.0482 192.317 72.654L190.115 74.8655Z"
      fill="#3183FF"
    />
    <rect
      x="163"
      y="106"
      width="34.6154"
      height="34.6154"
      rx="17.3077"
      fill="#D17EF7"
    />
    <path
      d="M188.962 114.654H171.654C170.596 114.654 169.731 115.519 169.731 116.577V128.115C169.731 129.173 170.596 130.038 171.654 130.038H176.462V131C176.462 131.529 176.894 131.962 177.423 131.962H183.192C183.721 131.962 184.154 131.529 184.154 131V130.038H188.962C190.019 130.038 190.875 129.173 190.875 128.115L190.885 116.577C190.885 115.51 190.019 114.654 188.962 114.654ZM188 128.115H172.615C172.087 128.115 171.654 127.683 171.654 127.154V117.538C171.654 117.01 172.087 116.577 172.615 116.577H188C188.529 116.577 188.962 117.01 188.962 117.538V127.154C188.962 127.683 188.529 128.115 188 128.115Z"
      fill="white"
    />
    <rect
      x="142"
      y="20"
      width="34.6154"
      height="34.6154"
      rx="17.3077"
      fill="#46CCEA"
    />
    <path
      d="M166.252 40.4502L163.809 40.1714C163.223 40.1041 162.646 40.306 162.232 40.7195L160.463 42.4887C157.742 41.1041 155.511 38.8829 154.127 36.1522L155.906 34.3733C156.319 33.9598 156.521 33.3829 156.454 32.7964L156.175 30.3733C156.059 29.4022 155.242 28.6714 154.261 28.6714H152.598C151.511 28.6714 150.607 29.5752 150.675 30.6618C151.184 38.8733 157.752 45.431 165.954 45.9406C167.04 46.0079 167.944 45.1041 167.944 44.0175V42.3541C167.954 41.3829 167.223 40.5656 166.252 40.4502Z"
      fill="white"
    />
    <path
      d="M32.3825 104.207L39.0857 86.3833"
      stroke="#4756DF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
