import { defineStore, skipHydrate } from "pinia";
import { useStorage } from "@vueuse/core";

type RoomSteps =
  | "PERMISSIONS"
  | "INVITE"
  | "WAITING_ROOM"
  | "MANAGE_WAITING_ROOM"
  | "MANAGE_PARTICIPANTS";

type RoomState = {
  finishedSteps: RoomSteps[];
};

export const useRoomOnboardingStore = defineStore("room-onboarding", () => {
  const roomState = useStorage<RoomState>("room-onboarding-state", {
    finishedSteps: [],
  });

  const finishRoomStep = (step: RoomSteps) => {
    if (roomState.value.finishedSteps.includes(step)) {
      return;
    }
    roomState.value.finishedSteps.push(step);
  };

  const isRoomStepFinished = (step: RoomSteps) => {
    return roomState.value.finishedSteps.includes(step);
  };

  const restartOnboarding = () => {
    roomState.value.finishedSteps = [];
  };

  return {
    roomState: skipHydrate(roomState),
    finishRoomStep,
    isRoomStepFinished,
    restartOnboarding,
  };
});
