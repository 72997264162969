<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="4" fill="#7EA6F7" />
    <path
      d="M8.00001 5.33333C8.73334 5.33333 9.33334 4.73333 9.33334 4C9.33334 3.26666 8.73334 2.66666 8.00001 2.66666C7.26667 2.66666 6.66667 3.26666 6.66667 4C6.66667 4.73333 7.26667 5.33333 8.00001 5.33333ZM8.00001 6.66666C7.26667 6.66666 6.66667 7.26666 6.66667 8C6.66667 8.73333 7.26667 9.33333 8.00001 9.33333C8.73334 9.33333 9.33334 8.73333 9.33334 8C9.33334 7.26666 8.73334 6.66666 8.00001 6.66666ZM8.00001 10.6667C7.26667 10.6667 6.66667 11.2667 6.66667 12C6.66667 12.7333 7.26667 13.3333 8.00001 13.3333C8.73334 13.3333 9.33334 12.7333 9.33334 12C9.33334 11.2667 8.73334 10.6667 8.00001 10.6667Z"
      fill="white"
    />
  </svg>
</template>
