import { defineStore, skipHydrate } from "pinia";
import { useStorage } from "@vueuse/core";
import { useBetaTesterStatusStore } from "./betaTesterStatus";

type BetaEndedStep =
  | { name: "START" }
  | { name: "SKIPPED"; counter: number }
  | { name: "FINISHED" };

type BetaEndedState = {
  currentStep: BetaEndedStep;
};

export const useBetaEndedOnboardingStore = defineStore(
  "beta-ended-onboarding",
  () => {
    const betaTesterStatus = useBetaTesterStatusStore();

    const betaEndedState = useStorage<BetaEndedState>(
      "beta-ended-onboarding-state",
      {
        currentStep: { name: "START" },
      },
    );

    watch(
      () => betaTesterStatus.isBetaTester,
      (newValue) => {
        if (
          newValue === false &&
          betaEndedState.value.currentStep.name === "START"
        ) {
          betaEndedState.value.currentStep = { name: "FINISHED" };
        }
      },
    );

    const setBetaEndedStep = (step: BetaEndedStep["name"]) => {
      if (
        betaEndedState.value.currentStep.name === "SKIPPED" &&
        step === "SKIPPED"
      ) {
        betaEndedState.value.currentStep = {
          ...betaEndedState.value.currentStep,
          counter: betaEndedState.value.currentStep.counter + 1,
        };
        if (betaEndedState.value.currentStep.counter >= 4) {
          betaEndedState.value.currentStep = { name: "FINISHED" };
        }
        return;
      }
      if (step === "SKIPPED") {
        betaEndedState.value.currentStep = {
          name: "SKIPPED",
          counter: 0,
        };
        return;
      }

      betaEndedState.value.currentStep = { name: step };
    };

    const isBetaEndedStepFinished = (step: BetaEndedStep["name"]) => {
      return betaEndedState.value.currentStep.name === step;
    };

    const shouldShowBetaEndedStep = computed(() => {
      return (
        betaTesterStatus.isBetaTester &&
        betaEndedState.value.currentStep &&
        betaEndedState.value.currentStep.name !== "FINISHED"
      );
    });

    return {
      betaEndedState: skipHydrate(betaEndedState),
      setBetaEndedStep,
      isBetaEndedStepFinished,
      shouldShowBetaEndedStep,
    };
  },
);
